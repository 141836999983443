import { liteClient } from "algoliasearch/lite";

import type { LiteClient } from "algoliasearch/lite";

let client: LiteClient | null = null;
let index: string | null = null;
let query: string | null = null;

import type { SearchResult } from "algoliasearch/lite";

const result = ref<SearchResult<any>>();

export const useAlgoliaSearch = (indexName: string) => {
  if (!client) {
    const config = useRuntimeConfig().public;
    client = liteClient(config.ALGOLIA_APPLICATION_ID, config.ALGOLIA_API_CLIENT_KEY);
    index = indexName;
  }

  const search = async (options: { query: string; searchParameters?: any; requestOptions?: any }) => {
    if (!client || !index) {
      throw new Error("Algolia client is not initialized");
    }

    const { data } = await useAsyncData(async () => {
      const response = await client!.search({
        requests: [{ indexName: index!, query: options.query, ...options.searchParameters, ...options.requestOptions }],
      });

      return response;
    });

    query = options.query;

    result.value = data.value?.results[0];
    return data.value?.results[0];
  };

  return {
    result,
    search,
    client,
    query,
  };
};
