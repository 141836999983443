
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46slug_93bxqMJRabVdWZXuvF9FWZmMIujcSvRxUBxs5JzQp4EB0Meta } from "/opt/build/repo/pages/[...slug].vue?macro=true";
import { default as index9iUtKXau4mRd0mXZzbFw297HW8Jat_OiICVegcAjmkIMeta } from "/opt/build/repo/pages/success/index.vue?macro=true";
import { default as _91_46_46_46slug_93UaJRQNm0ZpVvVAd0gy4O_45RcZOyxUEkGVF7tIv5IQVkQMeta } from "/opt/build/repo/pages/legal/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93gqPxRbX5LMRSwMCplhXxRKnghVSM13bm4cD9icCP57YMeta } from "/opt/build/repo/pages/global/[...slug].vue?macro=true";
import { default as _91_91form_93_93qa7lEsEz_45BlJO6MKo5tmywWWrxbs6we8Z01e7_45Dr_45o8Meta } from "/opt/build/repo/pages/success/[[form]].vue?macro=true";
import { default as _91_46_46_46slug_93niOX6RNh2bcUg_45lbLmk1yXb5ZWeAR0gup6FJ3o5Po4IMeta } from "/opt/build/repo/pages/qr-code/[...slug].vue?macro=true";
import { default as indexavKmjy0EqSWb2CqVekIr6RyQdlNc2QyJVMbD1lLbraYMeta } from "/opt/build/repo/pages/careers/open-roles/index.vue?macro=true";
import { default as _91_46_46_46slug_935DRWTnshuIpRhYXZ3iRJMXpkqwDXU876k6owdELrmSMMeta } from "/opt/build/repo/pages/careers/open-roles/[...slug].vue?macro=true";
import { default as _91_91tag_93_93Qucdm2TObLt4YIGZKbCDukIntpAAnyrYkZ_452rOVzhhgMeta } from "/opt/build/repo/pages/resources/[[type]]/tag/[[tag]].vue?macro=true";
import { default as component_45stubE86B9032rcV2J_cDe4Vtg3adsHBytwsL_y2ub6Qyo0sMeta } from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubE86B9032rcV2J_cDe4Vtg3adsHBytwsL_y2ub6Qyo0s } from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_93bxqMJRabVdWZXuvF9FWZmMIujcSvRxUBxs5JzQp4EB0Meta || {},
    component: () => import("/opt/build/repo/pages/[...slug].vue")
  },
  {
    name: "success",
    path: "/success",
    component: () => import("/opt/build/repo/pages/success/index.vue")
  },
  {
    name: "legal-slug",
    path: "/legal/:slug(.*)*",
    component: () => import("/opt/build/repo/pages/legal/[...slug].vue")
  },
  {
    name: "global-slug",
    path: "/global/:slug(.*)*",
    component: () => import("/opt/build/repo/pages/global/[...slug].vue")
  },
  {
    name: "success-form",
    path: "/success/:form?",
    component: () => import("/opt/build/repo/pages/success/[[form]].vue")
  },
  {
    name: "qr-code-slug",
    path: "/qr-code/:slug(.*)*",
    meta: _91_46_46_46slug_93niOX6RNh2bcUg_45lbLmk1yXb5ZWeAR0gup6FJ3o5Po4IMeta || {},
    component: () => import("/opt/build/repo/pages/qr-code/[...slug].vue")
  },
  {
    name: "careers-open-roles",
    path: "/careers/open-roles",
    component: () => import("/opt/build/repo/pages/careers/open-roles/index.vue")
  },
  {
    name: "careers-open-roles-slug",
    path: "/careers/open-roles/:slug(.*)*",
    component: () => import("/opt/build/repo/pages/careers/open-roles/[...slug].vue")
  },
  {
    name: "resources-type-tag-tag",
    path: "/resources/:type?/tag/:tag?",
    component: () => import("/opt/build/repo/pages/resources/[[type]]/tag/[[tag]].vue")
  },
  {
    name: component_45stubE86B9032rcV2J_cDe4Vtg3adsHBytwsL_y2ub6Qyo0sMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubE86B9032rcV2J_cDe4Vtg3adsHBytwsL_y2ub6Qyo0s
  }
]