<template>
  <div>
    <NuxtLayout>
      <section page-error>
        <section hero-centered>
          <div class="container">
            <div class="section-row">
              <div class="section-content">
                <h1 class="section-title" v-if="blok?.title && title != ''" v-html="title"></h1>
                <div class="section-description" v-if="blok?.description && description != ''" v-html="description"></div>
                <div v-if="blok?.buttons?.length > 0" class="section-buttons">
                  <StoryblokComponent v-for="blok in blok.buttons" :key="blok._uid" :blok="blok" />
                </div>
              </div>
            </div>
          </div>
          <div class="container" v-if="blok.image_bottom?.filename" :class="{ 'container--fluid': blok.style === 'full-width' }">
            <figure class="section-image" :class="{ 'section-has-mobile': blok.image_bottom_mobile?.filename }">
              <img v-if="blok.image_bottom?.filename" :src="optimizeImage(blok.image_bottom.filename)" :alt="`Hero Image ${blok.image_bottom.alt}`" class="section-image-element" />
              <img v-if="blok.image_bottom_mobile?.filename" :src="optimizeImage(blok.image_bottom_mobile.filename)" :alt="`Hero Image Mobile ${blok.image_bottom_mobile.alt}`" class="section-image-element-mobile" />
            </figure>
          </div>
        </section>
        <ClientOnly>
          <section page-search>
            <div class="container">
              <div class="section-row">
                <div class="section-content">
                  <div class="search-title">
                    We cannot find the page you are looking for <span>"{{ query }}"</span>.
                    <br />
                    <p v-text="search_description"></p>
                  </div>
                  <div class="search-row">
                    <div v-if="query && result?.hits.length > 0">
                      <div class="search-results">
                        <div class="search-result" v-for="(result, r) in result.hits" :key="r">
                          <a class="search-result-title" :href="result.url" v-html="result.title"></a>
                          <div class="search-result-breadcrumbs">
                            <ul v-if="result.breadcrumbs?.length > 1">
                              <li v-for="(folder, f) in result.breadcrumbs" v-text="folder" :key="f"></li>
                            </ul>
                          </div>
                          <div class="search-result-description" v-html="result._snippetResult.content.value"></div>
                        </div>
                      </div>
                    </div>
                    <div class="search-quicklinks">
                      <div class="search-title" v-if="query && result?.hits.length > 0">Quick Links:</div>
                      <div class="search-results">
                        <div class="search-result">
                          <a class="search-result-title" v-for="q in quicklinks" :key="q" v-text="q.title" :href="q.url"></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </ClientOnly>
      </section>
    </NuxtLayout>
  </div>
</template>
<style src="~/assets/scss/error.scss" lang="scss"></style>
<script setup>
  const props = defineProps({
    error: {
      type: Object,
    },
  });

  const route = useRoute();

  const query = ref("");

  const blok = computed(() => {
    return {
      title: {
        type: "doc",
        content: [
          {
            type: "paragraph",
            content: [{ text: props.error.statusMessage || props.error.message || props.error.statusCode, type: "text" }],
          },
        ],
      },
      background: {
        filename: "https://a-us.storyblok.com/f/1001817/2880x738/0b9c12f985/hero-bg.png",
      },
      buttons: [{ url: { id: "", url: "/", linktype: "url", fieldtype: "multilink", cached_url: "/" }, icon: "false", label: "Go back home", theme: "primary", component: "button" }],
      pages: {
        _uid: "82ef1c82-a87d-4e3f-a27a-7c2d32a3fce7",
        limit: "6",
        pages: ["6cc003f3-49a6-40b3-8446-42d6dce9b0a0", "caceb384-19ac-46cb-8110-25aaa746f4f6", "fd05ee9f-5515-4fc5-9d49-d01bfc81bf2d", "f40aa577-b8a7-4ab5-a669-b447742abc5e", "b6f14084-1deb-44ba-9ad2-ed0868572936", "4723ba03-c55a-4250-8ab4-6411857a95e2"],
        title: {
          type: "doc",
          content: [
            {
              type: "paragraph",
            },
          ],
        },
        header: "",
        component: "page-slider",
        description: {
          type: "doc",
          content: [
            {
              type: "paragraph",
              content: [
                {
                  text: "Explore our Whitepapers for insights, in-depth analysis and practical solutions to help you stay ahead. ",
                  type: "text",
                },
              ],
            },
          ],
        },
      },
    };
  });

  const title = computed(() => useRichText(blok.value.title));
  const description = computed(() => useRichText(blok.value.description));
  const search_description = ref("Here are some related pages you might be interested in:");
  const config = useRuntimeConfig().public;
  const { search } = useAlgoliaSearch(config.ALGOLIA_INDEX);
  const result = ref(null);

  const quicklinks = [
    { title: "Contact Us", url: "/contact-us" },
    { title: "Developer Portal", url: "https://developers.kudosity.com/" },
    { title: "SMS", url: "/products/messaging/sms" },
    { title: "MMS", url: "/products/messaging/mms" },
    { title: "Whatsapp", url: "/products/messaging/whatsapp" },
    { title: "See all products", url: "/products" },
  ];

  onMounted(async () => {
    console.log("page error", props.error);
    if (route.params.slug) {
      query.value = route.params.slug.join(" ").replace(/[^a-zA-Z]/g, " ");
      const response = await search({ query: query.value, requestOptions: { analytics: false, hitsPerPage: 3 } });

      result.value = response;

      if (result.value?.hits?.length === 0) {
        search_description.value = "Check our latest articles below:";
        await search({ query: "Articles", requestOptions: { analytics: false, restrictSearchableAttributes: ["breadcrumbs", "url"], hitsPerPage: 3 } });
      }

      if (result.value?.hits?.length === 0) {
        search_description.value = "Here are some helpful links instead:";
      }

      if (import.meta.client) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "Page not found",
        });
      }
    }
  });
</script>
